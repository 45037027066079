<template>
    <div>
      <div v-if="!isTimeOut && !isEndSessionModal ">
        <div class="justify-content-center justify-content-center mt-2 ml-4 mr-4 mb-2">
          <h2 class="black-text mb-4 bold">Session Timeout </h2>
          <h3 class="black-text mb-4"> <v-icon style="cursor: pointer;color: #000000;font-size: 21px;">info</v-icon> Warning</h3>
          <p class="contact-text ml-5">
            You will be logged out due to inactivity in:
          </p>
          <p class="contact-text mb-3 ml-5 count-down">
            <vue-countdown @start="start" @progress="progress" :time="countDownTime" @end="timeOut" :interval="1000" v-slot="{ minutes, seconds }">
              {{  minutes }} : {{ seconds }}
            </vue-countdown>
          </p>
          <b-button :disabled="isLoadingRefreshToken" @click="refleshToken" class="btn mt-3 btn-customize">
            <loader v-if="isLoadingRefreshToken" />
            Continue
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="justify-content-center mt-2 ml-4 mr-4 mb-2">
          <h2 class="black-text bold mb-4">Session Timeout</h2>
          <h3 class="black-text mb-4"> <v-icon style="cursor: pointer;color: #000000;font-size: 21px;">info</v-icon> Warning</h3>
          <p class="contact-text ml-5 mb-5">
            You login session has timed out. Please login again.
          </p>
          <b-button @click="logout" class="btn mt-3 btn-customize">Go to login page</b-button>
        </div>
      </div>
    </div>
</template>

<script>
import channel from "@/mixins/SessionControl";

import VueCountdown from '@chenfengyuan/vue-countdown';
import Loader from "@/components/Loader";
import {
  getRefleshToken,
  getAuthenticatedUserByCache, removeAuthenticatedUserByCache
} from "@/helpers";
export default {
  name: 'LoginConfirmModal',
  components:{
      VueCountdown,
      Loader
  },
  data () {
    return {
        isLoadingRefreshToken: false,
      countDownTime: null,
      isTimeOut: false,
      refToken: null,
      authToken: null,
    }
  },
  methods: {
    start() {

    },
    progress() {
      console.log('deneme')
    },
    logout() {
      removeAuthenticatedUserByCache();
      localStorage.clear()

      channel.postMessage("sessionClosed")
      window.location.href = '/auth/login';
    },
      refleshToken() {
            let refreshToken = this.refToken;
            this.isLoadingRefreshToken = true
            this.$store
            .dispatch("auth/reAuthentication", { refreshToken})
            .then(data => {
                localStorage.setItem('modalState', 'hidden')
                this.isLoadingRefreshToken = false
                this.receveidStatusChanges(false)
                this.$emit('setRemain',true)
            })
            .catch((e) => {
              console.log(e)
                this.isLoadingRefreshToken = false
                this.receveidStatusChanges(false)
            });
      },
      receveidStatusChanges(status) {
        this.$emit("EndSessionModalShow", status);
      },
      timeOut(){
        localStorage.removeItem('authenticatedUser')
        channel.postMessage("sessionClosed")
          this.isTimeOut = true
        this.$router.push({path: '/auth/login'})
      }
  },
  computed:{
      authenticatedUser(){
          return getAuthenticatedUserByCache();
      },
    isEndSessionModal() {
      return localStorage.getItem('EndSessionModal')
    }
  },
  created() {
  this.refToken = getAuthenticatedUserByCache().merchantPanelRefleshToken
    this.authToken = getAuthenticatedUserByCache().merchantPanelToken
    this.countDownTime = parseInt(process.env.VUE_APP_COUNTDOWN_LOGIN_TIME, 10) * 1000
  }
}
</script>

<style scoped>
    .bold{
        font-weight: bold;
        font-size: 20px im !important;
    }
    .black-text{
        color: #000000;
        font-size: 21px;
    }

    a.black-text{
        color: #000000;
        font-weight: bold;
        text-decoration: underline;
    }
    .btn-customize{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: #fff !important;
        padding: 5px;
        font-size: 15px;
        width: 35%;
        float: right;
    }

    .btn-customize:active{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: #fff !important;
        padding: 5px;
        font-size: 15px;
        width: 35%;
        float: right;
    }

    .contact-text{
        color: #000000;
        font-family: Roboto;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }
    .count-down{
        font-weight: bold;
        font-size: 19px;
    }
</style>
