import { parseDomain, ParseResultType } from "parse-domain";
import jwt from 'jsonwebtoken';

import {
  DOMAIN_INFO_KEY,
  AUTHENTICATED_USER,
  BUSINESS_INFO,
  PAYMENT_INFO,
  WALLET_INFO,
  ASK_REFLESH_TOKEN_PERIOD
} from "../store/constants";

import moment from "moment";

export const rejectError = ({ response = null }) => {
  let message =
    "The server encountered an error which may have several reasons such as poor connectivity or internal server error.";
  if (response) {
    message = response.data;
  }
  return Promise.reject(message);
};

export const applyFilters = (url, filter) => {
  if (filter) {
    let filteredEntities = "";
    if (url.indexOf("?") === -1) {
      url += "?";
    } else {
      url += "&";
    }

    Object.keys(filter).forEach(key => {
      filteredEntities += `${key}=${filter[key]}&`;
    });

    if (filteredEntities.slice(-1) === "&") {
      filteredEntities = filteredEntities.slice(0, -1);
    }

    return url + filteredEntities;
  }

  return url;
};

export const processLocation = location => {
  return location
    .toLowerCase()
    .replace(/[\s,]+/g, "")
    .trim();
};

export const getAPIURLInfo = () => {
  const host = parseDomain(location.hostname);
  console.log('hosy', host)
  const typedHost = host.type === ParseResultType.Reserved ? parseDomain(process.env.VUE_APP_HOST) : host;

  let domain = typedHost.domain;
  let hostName = `${domain}.${typedHost.topLevelDomains[0]}`;
  let subDomain = process.env.VUE_APP_SUB_DOMAIN;
  let backendLocal = process.env.VUE_APP_BACKEND_LOCAL;
  const apiBaseUrl =
    backendLocal === "true"
      ? `http://localhost:8011/api/v1`
      : `https://${subDomain}.${domain}.${typedHost.topLevelDomains[0]}/api/v1`;

  const apiDocUrl = `https://dev.${hostName}/bitpace-merchant-rest-api`;
  const pluginsPageUrl = `https://www.${hostName}/plugins`;
  const staticResourceRoot= process.env.VUE_APP_STATIC_RESOURCE_ROOT;
  const defaultLogoUrlWhite = (host.type === ParseResultType.Reserved &&  process.env.VUE_APP_HOST === null) ?  `${staticResourceRoot}/default/logo_white.png`: `${staticResourceRoot}/${domain}/logos/logo_white.png`;
  const defaultLogoUrlBlack = (host.type === ParseResultType.Reserved &&  process.env.VUE_APP_HOST === null) ?  `${staticResourceRoot}/default/logo_black.png`: `${staticResourceRoot}/${domain}/logos/logo_black.png`;
  const websiteUrl = `https://www-dev.${hostName}`;
  const appUrl = `https://merchant-dev.${hostName}`;
  const sandboxMerchantUrl = `https://merchant-sandbox.${hostName}`;
  const devMerchantUrl = `https://merchant-dev.${hostName}`;
  const productionMerchantUrl = `https://merchant.${hostName}`;

  return {
    apiBaseUrl,
    domain,
    hostName,
    apiDocUrl,
    pluginsPageUrl,
    defaultLogoUrlWhite,
    defaultLogoUrlBlack,
    websiteUrl,
    appUrl,
    sandboxMerchantUrl,
    devMerchantUrl,
    productionMerchantUrl,
    staticResourceRoot,
  };
};

export const localStorageRemoveObject = (key) => {
  localStorage.removeItem(key);
};

export const localStorageSetObject = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const localStorageGetObject = key => {
  return JSON.parse(localStorage.getItem(key));
};

export const getDomainInfoByCache = () => {
  return localStorageGetObject(DOMAIN_INFO_KEY);
};

export const saveDomainInfoToCache = value => {
  return localStorageSetObject(DOMAIN_INFO_KEY, value);
};

export const removeDomainInfoByCache = () => {
  return localStorageRemoveObject(DOMAIN_INFO_KEY);
};

export const saveAuthenticatedUserToCache = value => {
  let tokenPeriod = localStorageGetObject(ASK_REFLESH_TOKEN_PERIOD);
  const expiry = moment
    .unix(value.exp)
    .subtract(2, "minutes").unix();

  if (value.isRefleshToken === 'true') {
    localStorageSetObject(ASK_REFLESH_TOKEN_PERIOD, {
      expiry,
      remainTime: tokenPeriod ? tokenPeriod.remainTime : 120000
    });
  }
  return localStorageSetObject(AUTHENTICATED_USER, value);
};

export const getAuthenticatedUserByCache = () => {
  return localStorageGetObject(AUTHENTICATED_USER);
};

export const removeAuthenticatedUserByCache = () => {
  localStorageRemoveObject(ASK_REFLESH_TOKEN_PERIOD);
  return localStorageRemoveObject(AUTHENTICATED_USER);
};

export const removeBusinessInfo = () => {
  return localStorageRemoveObject(BUSINESS_INFO);
};

export const getPaymentInfoByCache = () => {
  return localStorageGetObject(PAYMENT_INFO);
};

export const savePaymentInfoToCache = value => {
  return localStorageSetObject(PAYMENT_INFO, value);
};

export const removePaymentInfo = () => {
  return localStorageRemoveObject(PAYMENT_INFO);
};

export const getWalletInfoByCache = () => {
  return localStorageGetObject(WALLET_INFO);
};

export const saveWalletInfoToCache = value => {
  return localStorageSetObject(WALLET_INFO, value);
};

export const removeWalletInfo = () => {
  return localStorageRemoveObject(WALLET_INFO);
};

export const getRefleshToken = () => {
  return localStorageGetObject(ASK_REFLESH_TOKEN_PERIOD);
};

export const updateRefleshToken = value => {
  if (value.remainTime <= 0) {
    removeAuthenticatedUserByCache();
  } else {
     return localStorageSetObject(ASK_REFLESH_TOKEN_PERIOD, value);
  }
};

export const refleshedTokenByAuth = value => {
  const expiry = moment.unix(value.tokenExpireTime).subtract(2, "minutes").unix();
     localStorageSetObject(ASK_REFLESH_TOKEN_PERIOD, {
       expiry,
       remainTime: 120000
     });
  return localStorageSetObject(AUTHENTICATED_USER, value);
};

export const checkTokenValidity = (token) => {
  if (token) {
      const decodedToken = jwt.decode(token)
      return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
  }
  return false
}

export const walletAddressRegexes = {
  BTC_BITCOIN: /^(bc1.*)[a-km-zA-HJ-NP-Z0-9]|[13][a-km-zA-HJ-NP-Z0-9]{26,33}$/,
  BTC_BTC: /^(bc1.*)[a-km-zA-HJ-NP-Z0-9]|[13][a-km-zA-HJ-NP-Z0-9]{26,33}$/,
  LTC_LTC: /^(ltc1|[LM])[a-zA-HJ-NP-Z0-9]{26,40}$/,
  ETH_ERC20: /^(0x)?[0-9a-fA-F]{40}$/,
  ETH_ERC21: /^(0x)?[0-9a-fA-F]{40}$/,
  USDT: /^(0x)?[0-9a-fA-F]{40}$/,
  USDC: /^(0x)?[0-9a-fA-F]{40}$/,
  BCH_BCH: /^([13][a-km-zA-HJ-NP-Z1-9]{25,34})|^((bitcoincash:)?(q|p)[a-z0-9]{41})|^((BITCOINCASH:)?(Q|P)[A-Z0-9]{41})$/,
  XRP_XRP: /^r[rpshnaf39wBUDNEGHJKLM4PQRST7VWXYZ2bcdeCg65jkm8oFqi1tuvAxyz]{27,35}$/,
  DASH_DASH: /^(X|7)[1-9A-HJ-NP-Za-km-z]{33}$/,
  IOTA_IOTA: /(^[9A-Z]{81}$|^[9A-Z]{90}$)/,
  BAT_BAT: /^(0x)?[0-9a-fA-F]{40}$/,
  TRX_TRX: /^(T{1})[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz$]{33}$/,
  ZEC_ZEC: /(^t1[a-zA-Z0-9]{33}$|^z1[a-zA-Z0-9]{33}$)/,
  ALGO_ALGO: /(^[A-Z0-9]{58}$)/,
  XLM_XLM: /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ234567]{56}$/,
  PAX_PAX: /^(0x)?[0-9a-fA-F]{40}$/,
  LINK_LINK: /^(0x)?[0-9a-fA-F]{40}$/,
  TEST_TEST: /^(0x)?[0-9a-fA-F]{40}$/,
  ADA_ADA: /^((Ae2)[a-km-zA-HJ-NP-Z0-9]{44,56})|^((DdzFF)[a-km-zA-HJ-NP-Z0-9]{99})|^((addr1)[a-km-zA-HJ-NP-Z0-9]{53,98})$/,
  DOGE_DOGE: /^D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}$/,
  SOL_SOL: /[1-9A-HJ-Za-km-z]{32,44}/,
  DOT_DOT: /^1[1-9A-Za-z][^OI0l]{45,55}$/,
  AVAX_AVAX: /^(0x)?[0-9a-fA-F]{40}$/,
  TRX_TRC20: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
  USDT_TRC20: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
  USDC_TRC20: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
  USDT_ERC20: /^(0x)[0-9A-Fa-f]{40}$/,
  USDC_ERC20: /^(0x)[0-9A-Fa-f]{40}$/,
  USDT_BEP20: /^(0x)[0-9A-Fa-f]{40}$/,
  USDC_BEP20: /^(0x)[0-9A-Fa-f]{40}$/,
  BNB_BEP20: /^(0x)[0-9A-Fa-f]{40}$/,
  USDT_BSC: /^(0x)[0-9A-Fa-f]{40}$/,
  USDC_BSC: /^(0x)[0-9A-Fa-f]{40}$/,
}
