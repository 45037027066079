<template>
  <div
    :class="sidebarbg"
    class="app-sidebar sidebar-shadow"
    @mouseover="toggleSidebarHover('add','closed-sidebar-open');"
    @mouseleave="toggleSidebarHover('remove','closed-sidebar-open');"
  >
    <div class="app-header__logo">
      <a :href='redirectUrl' class="logo-src">
        <img class="logo-default" :src="logoUrl" height="30px" alt="">
      </a>
      <div class="header__pane ml-auto">
        <div class="app-header__mobile-menu">
          <div>
            <button
              type="button"
              class="hamburger close-sidebar-btn hamburger--elastic"
              v-bind:class="{ 'is-active' : true }"
              @click="toggleMobile('closed-sidebar-open')"
            >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <button
          type="button"
          class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : isOpen }"
          @click="toggleBodyClass('closed-sidebar')"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-sidebar-content" v-if="showMenu">
      <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
        <sidebar-menu showOneChild :menu="filterMenu(menu)" />
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {getAPIURLInfo, getDomainInfoByCache, getAuthenticatedUserByCache} from "@/helpers";

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar
  },
  data() {
    return {
      isOpen: false,
      sidebarActive: false,
      menu: [
        {
          header: true,
          title: " "
        },
        {
          title: "Onboarding",
          icon: "pe-7s-box2",
          href: "/onboarding",
          isDisabled: `${
            getDomainInfoByCache().page_config.password_reset_disabled || (localStorage.getItem('dismissOnboarding') == undefined || localStorage.getItem('dismissOnboarding') == null || localStorage.getItem('dismissOnboarding') == 'true') ? true : false
          }`
        },
        {
          title: "Dashboard",
          icon: "pe-7s-graph1",
          href: "/"
        },
        {
          title: "Sub Accounts",
          icon: "pe-7s-users",
          href: "/sub-accounts",
          roles: ["SUB_MERCHANT_VIEW"],
          isDisabled: `${ true }` // will be enabled by business info response below
        },
        {
          title: "Order List",
          icon: "pe-7s-browser",
          href: "/orders",
        },
        {
          title: "Trade",
          icon: "vsm-icon pe-7s-cash",
          href: "/trade-coin",
          roles: ["TRADE_COIN"],
          child:[
            {
              title: "Buy",
              href: "/trade-coin-buy",
            },
            {
              title: "Sell",
              href: "/trade-coin-sell",
            },
            {
              title: "Deposit",
              href: "/trade-coin-deposit",
            },
            {
              title: "Withdraw",
              href: "/trade-coin-withdraw",
              roles: ["WITHDRAW_REQUEST_MANAGEMENT"],
            },
          ]
        },
        {
          title: "Mass Payout",
          icon: "vsm-icon pe-7s-cash",
          href: "/mass-payout",
          roles: ["MASS_PAYOUT"],
        },
        {
          title: "Earn",
          icon: "vsm-icon pe-7s-cash",
          href: "/earn",
          roles: ["EARN_MANAGEMENT"],
          child:[
            {
              title: "Activities",
              href: "/earn/activities",
              roles: ["EARN_MANAGEMENT"],
            },
          ]
        },
        {
          title: "Make Payment",
          icon: "pe-7s-cash",
          href: "/make-payment",
          roles: ["MAKE_PAYMENT"]
        },
        {
          title: "Receive Payment",
          icon: "pe-7s-cash",
          href: "/receive-payment",
          roles: ["RECEIVE_PAYMENT"],
        },
        {
          title: "Receive Deposit",
          icon: "pe-7s-cash",
          href: "/receive-deposit",
          roles: ["DEPOSIT"],
        },
        {
          title: "Deposit Money",
          icon: "pe-7s-plugin",
          href: "/deposit-money",
          roles: ["BANK_ACCOUNT_MANAGEMENT"],
          isDisabled: `${
            getDomainInfoByCache().page_config
              .deposit_money_page_disabled
          }`
        },
        {
          title: "Withdraw Money",
          icon: "pe-7s-cash",
          href: "/withdraw-money",
          roles: ["WITHDRAW_REQUEST_MANAGEMENT"],
          isDisabled: `${getDomainInfoByCache().page_config.withdraw_money_page_disabled}`
        },
        {
          title: "Users",
          icon: "pe-7s-user",
          href: "/users",
          roles: ["USER_MANAGEMENT_ACTIONS"]
        },
        {
          title: "Support",
          icon: "pe-7s-help1",
          href: "/support",
          roles: ["DISPLAYING_SUPPORT"],
          isDisabled: `${getDomainInfoByCache().page_config.support_page_disabled}`
        },
        {
          title: "Plugins",
          icon: "pe-7s-cart",
          href: "/plugins",
          isDisabled: `${
            getDomainInfoByCache().page_config
              .plugins_page_disabled
          }`
        },
        {
          title: "Account Settings",
          icon: "pe-7s-users",
          child:[
            {
              title: "Merchant Information",
              href: "/information",
            },
            {
              title: "Rates Fees and Limits",
              href: "/rates-fees-limits",
            },
            {
              title: "Payment Settings",
              href: "/payment-setting",
              roles: ["PAYMENT_SETTINGS"],
            },
            {
              title: "Auto Conversion",
              href: "/auto-conversion",
              roles: ["AUTO_CONVERSION"],
            },
            {
              title: "Bank Account Detail",
              href: "/bank-details",
              roles: ["BANK_ACCOUNT_MANAGEMENT"],
              isDisabled: `${getDomainInfoByCache().page_config.bank_account_page_disabled}`
            },
            {
              title: "Currencies",
              href: "/currencies",
              roles: ["CURRENCY_MANAGEMENT"],
            },
            {
              title: "API",
              href: "/api/management",
              roles: ["API_MANAGEMENT"],
              isDisabled: `${
                getDomainInfoByCache().page_config
                  .api_doc_disabled || getAuthenticatedUserByCache().userVerified === 'false'
              }`
            },
            {
              title: "Developer Hub",
              href: "/developer-hub",
              isDisabled: `${
                getDomainInfoByCache().page_config
                  .api_doc_disabled
              }`
            },
            {
              title: "Crypto-Asset Catalogue",
              href: "/crypto-asset-catalogue",
            },
          ]
        },
      ],
      collapsed: true,
      windowWidth: 0,
      logoUrl: null,
      domainInfo: getDomainInfoByCache(),
      sideMenuOpen: false,
      merchantInfo: {},
      showMenu: false
    };
  },
  props: {
    sidebarbg: String
  },
  methods: {
    filterMenu(menu) {
      const enableAutoConversionSettings = this.merchantBusinessInfo.enableAutoConversionSettings;

      const rolesStr = localStorage.getItem('authenticatedUser');
      const userRoles = JSON.parse(rolesStr).roles;
      let filteredMenu = [];

      menu.forEach(function(element){
        if (element.isDisabled === 'true') { return; }

        if (element.hasOwnProperty("roles")) {
          const found = element.roles.some(r=> userRoles.includes(r));
          if (found) {
            let subMenus = element.child;
            let subFilteredMenu = [];

            if (subMenus) {
              subMenus.forEach(subMenu => {
                if (subMenu.isDisabled === 'true') {
                  return;
                }
                if (subMenu.hasOwnProperty("roles")) {
                  const found = subMenu.roles.some(r => userRoles.includes(r));
                  if (found) {
                    subFilteredMenu.push(subMenu);
                  }
                } else {
                  subFilteredMenu.push(subMenu)
                }
              });

              if (subFilteredMenu.length < 1) {
                delete element.child;
              } else {
                element.child = subFilteredMenu;
              }
            }

            filteredMenu.push(element);
          }
        } else {
          let subMenus = element.child;

          if (subMenus) {
            let subFilteredMenu = [];
            subMenus.forEach(subMenu => {

              if (subMenu.isDisabled === 'true') { return; }
              if (subMenu.hasOwnProperty("roles")) {
                const found = subMenu.roles.some(r=> userRoles.includes(r));
                if(found){
                  subFilteredMenu.push(subMenu);
                }
              } else {
                subFilteredMenu.push(subMenu)
              }
            });

            for(let [index, item] of subFilteredMenu.entries()){
              if(item.title == 'Auto Conversion' && !enableAutoConversionSettings){
                subFilteredMenu.splice(index, 1)
              }
            }

            element.child = subFilteredMenu;
            filteredMenu.push(element);
          } else {
            filteredMenu.push(element);
          }
        }
      });

      return filteredMenu;
    },
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        el.classList.add('logo-normalize')
        el.classList.add(className);
      } else {
        el.classList.remove('logo-normalize')
        el.classList.remove(className);
      }
    },
    toggleSidebarHover(add, className) {
      const el = document.body;
      this.sidebarActive = !this.sidebarActive;

      this.windowWidth = document.documentElement.clientWidth;

      if(add === "add"){
        el.classList.add("expanded-sidebar");
        el.classList.remove("small-expanded");
      }else{
        if(this.windowWidth > "992" && this.windowWidth < "1350"){
          el.classList.add("small-expanded");
          el.classList.remove("expanded-sidebar");
        }

        if(el.classList.contains("logo-normalize")){
          el.classList.add("small-expanded");
          el.classList.remove("expanded-sidebar");
        }
      }

      if (this.windowWidth > "992") {
        if (add === "add") {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      }
    },
    getWindowWidth() {
      const el = document.body;
      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < "1350") {
        el.classList.add("closed-sidebar", "closed-sidebar-md", "small-expanded");
        el.classList.remove("expanded-sidebar");
      } else {
        el.classList.remove("closed-sidebar", "closed-sidebar-md", "small-expanded");
        el.classList.add("expanded-sidebar");
      }
    },
    toggleMobile(className) {
        const el = document.body;
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            el.classList.add(className);
        } else {
            el.classList.remove(className);
        }
    },
    async enableSubAccountInSidebar() {
      for( var item of this.menu) {
        if(item.title === 'Sub Accounts') {
          let hasSubMerchant = await this.$store.getters["merchant/getMerchantBusinessInfo"].hasSubMerchant
          item.isDisabled = `${!hasSubMerchant}`
        }
      }
    },
    async loadBusinessInformation() {
      await this.$store
        .dispatch("merchant/getMerchantBusinessInfo", {})
        .then(() => {
          this.enableSubAccountInSidebar();
          this.showMenu = true;
        })
        .catch((err) => {
          if(process.env.NODE_ENV === "development") {
            window.console.log(err);
          }
        });
    },
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
      //Init
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  async created(){
    await this.loadBusinessInformation();
    this.logoUrl = getAPIURLInfo().defaultLogoUrlBlack;
  },
  computed:{
    merchantBusinessInfo() {
      return this.$store.getters["merchant/getMerchantBusinessInfo"];
    },
    redirectUrl(){
      return this.redirectMainPageDisabled === true ? "javascript:void(0)" : "/";
    },
    redirectMainPageDisabled(){
      return this.domainInfo ? this.domainInfo.page_config.redirect_main_page_disabled: true;
    }
  }
};
</script>

<style scoped>

.small-expanded .logo-default{
  display: none;
}

.small-expanded .logo-small-default{
 display: block !important;
   width: 35px !important;
}

.expanded-sidebar .logo-default{
  display: block;
}

.expanded-sidebar .logo-small-default{
  display: none;
}

.closed-sidebar-open .logo-small-default{
  display: none;
  width: 35px !important;
}

.closed-sidebar-open .logo-default{
  display: block !important;
}

.app-sidebar {
  padding-bottom: 100px;
  background-color: #fff;
}
</style>
