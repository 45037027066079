import axios from 'axios'
import { NONE_AUTH_URLS} from '../store/constants';
import {
  getAPIURLInfo,
  removeAuthenticatedUserByCache,
  updateRefleshToken,
  getRefleshToken
} from "@/helpers";
import moment from "moment";

const baseURL = getAPIURLInfo().apiBaseUrl;

const axiosInstance = axios.create({
    timeout: 60000,
    baseURL,
})
const activeRequests = {};

axiosInstance.interceptors.request.use(function (config) {
  let url = config.url;

    // this code for /self-registration/self-registration-request/validateConfirmationMail/192932932 case
    if(!url.includes('self-registration-request') && !url.includes('merchants/panel/ip-list/validate')){
      if(!NONE_AUTH_URLS.includes(url)){
        let authenticatedUser = JSON.parse(localStorage.getItem("authenticatedUser"));
        let token = authenticatedUser.merchantPanelToken;
        if (token && token !== undefined) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
    }
  if (activeRequests[url]) {
    activeRequests[url].abortController.abort();
  }
  const abortController = new AbortController();
  const signal = abortController.signal;
  if(url === '/orders/panel/prices') {
    activeRequests[url] = {
      abortController,
    };
  }


  config.signal = signal;
    return config
}, function (err) {
    removeAuthenticatedUserByCache();
    return Promise.reject(err)
});

axiosInstance.interceptors.response.use(

  async (res) => {
    const { config } = res;
    const { url } = config;
    delete activeRequests[url];
    const IDLETIME = process.env.VUE_APP_IDLE_TIME
    localStorage.setItem('remainTime', IDLETIME)
    localStorage.setItem('startTime', Date.now());
      return res;
    },
    async (error) => {
      const { config } = error;
      console.log(error)
      console.log(config)
      const { url } = config;

      delete activeRequests[url];
      if (error.response) {
        if (error.response.status === 401 || error.response.data.code === "302") {
          localStorage.clear();
          window.location.href = "/"
        }
      }
      const IDLETIME = process.env.VUE_APP_IDLE_TIME
      localStorage.setItem('remainTime', IDLETIME)
      localStorage.setItem('startTime', Date.now());
      return Promise.reject(error);
    }
  );

  export default axiosInstance

